<style scoped>
  .loader-small {
    transform: translate(18%, 18%);
  }
  .loader-normal {
    transform: translate(50%, 50%);
  }
</style>

<template>
  <div>
    <!-- <base-card
      color="#fc4605"
    >
      <v-container>
        <v-row>
          <v-responsive
            class="overflow-visible mr-2"
          >
            
            
          </v-responsive>
        </v-row>
      </v-container>
    </base-card> -->
    <!-- <div style="position: fixed; bottom: 0px; left: 0px; z-index: 1;">
      <v-img
              ref="image"
              :src="carvedPumpkinImg"
              width="100px"
              style="transform: rotate(36deg)  translateY(26px);
                    -moz-transform: rotate(36deg)  translateY(26px);
                    -webkit-transform: rotate(36deg) translateY(26px);
                    background-size: 70px;
                    margin-top: -10px;
                    image-rendering: pixelated;
                    display: inline-block;"
            />
    </div> -->
    <base-card
      color="rgba(203, 170, 92, 0.65)"
      class="pa-2"
    >
      <v-container>
        <v-row class="pa-2">
          <v-responsive
            class="overflow-visible mr-2"
          >
            <base-subheading>Mini Converter Online 🤖</base-subheading>
            <p>Enter your Java Minecraft Username and optionally select a Helmet and Weapon to weild!</p>

            <v-text-field
              label="Enter Minecraft username"
              v-model="username"
            ></v-text-field>

            <!-- <div class="d-flex"> -->
              <div class="d-flex">
                <v-combobox
                  label="Helmet (optional)"
                  v-model="selectedHelmet"
                  :items="helmets"
                >
                  <template v-slot:selection="data">
                    <v-chip
                      :key="JSON.stringify(data.item)"
                      v-bind="data.attrs"
                      :input-value="data.selected"
                      :disabled="data.disabled"
                      @click:close="data.parent.selectItem(data.item)"
                    >
                      <v-avatar
                        size="36px"
                      >
                        <img
                          alt="Weapon"
                          :src="getHelmetImage(data.item.id)"
                        >
                      </v-avatar>
                      &nbsp;{{ data.item.text }}
                    </v-chip>
                  </template>

                  <template v-slot:item="{ item }">
                    <v-avatar
                      size="36px"
                    >
                      <img
                        alt="Helmet"
                        :src="getHelmetImage(item.id)"
                      >
                    </v-avatar>
                    &nbsp;
                    {{ item.text }}
                  </template>
                </v-combobox>
                <v-icon
                  v-if="selectedHelmet"
                  @click="selectedHelmet = ''"
                  small
                >
                  $delete
                </v-icon>
              </div>

              <div class="d-flex">
                <v-combobox
                  label="Weapon (optional)"
                  v-model="selectedWeapon"
                  :items="weapons"
                >
                  <template v-slot:selection="data">
                    <v-chip
                      :key="JSON.stringify(data.item)"
                      v-bind="data.attrs"
                      :input-value="data.selected"
                      :disabled="data.disabled"
                      @click:close="data.parent.selectItem(data.item)"
                    >
                      <v-avatar
                        size="36px"
                      >
                        <img
                          alt="Weapon"
                          :src="getWeaponImage(data.item.id)"
                        >
                      </v-avatar>
                      &nbsp;{{ data.item.text }}
                    </v-chip>
                  </template>

                  <template v-slot:item="{ item }">
                    <v-avatar
                      size="36px"
                    >
                      <img
                        alt="Avatar"
                        :src="getWeaponImage(item.id)"
                      >
                    </v-avatar>
                    &nbsp;
                    {{ item.text }}
                  </template>
                </v-combobox>
                <v-icon
                  v-if="selectedWeapon"
                  @click="selectedWeapon = ''"
                  small
                >
                  $delete
                </v-icon>
              </div>
            <!-- </div> -->
            
            <div class="d-flex">
              <v-checkbox
                label="Wearing a Hoodie?"
                v-model="forceHoodie"
                class="d-flex mr-4"
              >
              </v-checkbox>

              <v-checkbox
                label="Enchant Helmet and or Weapon?"
                v-model="enchantItems"
                class="d-flex"
              >
              </v-checkbox>
            </div>

            <v-btn
              :block="$vuetify.breakpoint.xsOnly"
              class="ma-0 mr-4 mb-4"
              color="secondary"
              style="height: 48px"
              :disabled="isLoading"
              @click="getMini"
            >
              {{ buttonText }}

              <moon-loader :loading="isLoading" color="#000" size="24px"></moon-loader>
            </v-btn>
            
          <v-btn
            :block="$vuetify.breakpoint.xsOnly"
            class="ma-0 mb-4"
            color="secondary"
            style="height: 48px"
            v-if="searchedPerformed"
            @click="downloadMini"
          >
            Save Mini
          </v-btn>

            <div>
              <h4 style="color: #f00">{{ errorMessage }}</h4>
            </div>

          </v-responsive>

          <div>
            <moon-loader :loading="isLoading" color="#000" size="250px" 
              style="position: absolute; z-index: 1;"
              :class="{'loader-small': $vuetify.breakpoint.xsOnly, 'loader-normal': !$vuetify.breakpoint.xsOnly}">
            </moon-loader>
            <v-img
              max-width="474px"
              max-height="474px"
              ref="image"
              :src="generatedImage"
              :width="$vuetify.breakpoint.xsOnly ? 'calc(100vw - 33px)' : 'unset'"
            /> <!--
              style="width: calc(100vw - 33px);"-->
          </div>
          <!--
              v-if="searchedPerformed" -->
        </v-row>
      </v-container>
    </base-card>
  </div>
</template>

<script>
  import axios from 'axios';
  import MoonLoader from 'vue-spinner/src/MoonLoader.vue'

  export default {
    name: 'MiniGenerator',

    components: {
      MoonLoader
    },

    data () {
      return {
        username: '',
        selectedHelmet: '',
        selectedWeapon: '',
        forceHoodie: false,
        enchantItems: false,

        helmets: [ '' ],
        weapons: [ '' ],

        buttonText: '',
        generatedImage: '',
        isLoading: false,
        errorMessage: '',
        searchedPerformed: false,

        carvedPumpkinImg: require(`@/assets/misc/carved_pumpkin.png`),
        miniSpinnerSmall: 'translate(18%, 18%)',
        miniSpinnerLarge: 'translate(50%, 50%)',

        onlineUrl: process.env.NODE_ENV === 'production' ?
          'http://miniconverter.dedimc.io:30412' :
          'http://miniconverterdev.dedimc.io:30029'
      }
    },

    methods: {
      getMini() {
        if (this.username == '') {
          this.errorMessage = 'Enter a username';
          return;
        }

        this.isLoading = true;
        this.errorMessage = '';
        this.buttonText = 'Making Mini ... ';
        this.searchedPerformed = false;
        var addHelmet = this.selectedHelmet != '';
        var addWeapon = this.selectedWeapon != '';

        const config = { url: this.onlineUrl + '/api/mini', method: "post", responseType: "blob", data: {
            username: this.username,
            addHelmet: addHelmet,
            helmet: this.selectedHelmet.id,
            addWeapon: addWeapon,
            weapon: this.selectedWeapon.id,
            forceHoodie: this.forceHoodie,
            enchant: this.enchantItems
        } };

        axios.request(config)
          .then(response => {
            this.rawMiniData = response.data;
            this.generatedImage = URL.createObjectURL(response.data);
            this.searchedPerformed = true;
          })
          .catch(err => {
            debugger;
            new Promise((resolve, reject) => {
              let reader = new FileReader();
              reader.onload = () => {
                err.response.data = JSON.parse(reader.result);
                resolve(err);
              };

              if (err.response) {
                reader.readAsText(err.response.data);
              }
            })
            .then(err => {
              console.log(err.response.data);
              if (err.response.status == 429) {
                this.errorMessage = 'Service is very busy at the moment, try again later 😢';
              } else {
                this.errorMessage = err.response.data.message;
              }
            })
          })
          .finally(() => {
            this.isLoading = false;
            this.buttonText = 'Make Mini';
          });
      },
      downloadMini() {
        const link = document.createElement("a");

        // Set link's href to point to the Blob URL
        link.href = this.generatedImage;
        link.download = `${this.username}-mini.png`;

        // Append link to the body
        document.body.appendChild(link);

        // Dispatch click event on the link
        // This is necessary as link.click() does not work on the latest firefox
        link.dispatchEvent(
          new MouseEvent('click', { 
            bubbles: true, 
            cancelable: true, 
            view: window 
          })
        );

        // Remove link from body
        document.body.removeChild(link);
      },
      titleCase(str) {
        return str
          .split(' ')
          .map((word) => word[0].toUpperCase() + word.slice(1).toLowerCase())
          .join(' ');
      },
      setDefaultImage() {
        this.generatedImage = require(`@/assets/minis/mrjoshuat-b90b0662-208d-43aa-fa2f-08d93be575f2.png`);
      },
      getConfigItems() {
        axios
          .get(this.onlineUrl + '/api/config')
          .then(response => {
            this.helmets = response.data.helmets.map(_ => {
              return {
                id: _,
                text: this.titleCase(_).replaceAll('_', ' ')
              };
            });
            this.weapons = response.data.weapons.map(_ => {
              return {
                id: _,
                text: this.titleCase(_).replaceAll('_', ' ')
              };
            });
          });
      },
      getWeaponImage(id) {
        return require(`@/assets/weapons/${id}.png`);
      },
      getHelmetImage(id) {
        if (id == 'gold') {
          id = 'golden';
        }
        return require(`@/assets/helmets/${id}_helmet.png`);
      }
    },

    mounted() {
      this.buttonText = 'Make Mini';

      this.getConfigItems();
      this.setDefaultImage();
    }
  }
</script>
